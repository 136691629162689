import { computed, Injectable } from '@angular/core';
import { APIService } from '@services/api.service';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { GroupService } from '@services/group.service';
import { InverterService } from '@services/inverter.service';

@Injectable({
  providedIn: 'root',
})
export class PotentialService {
  constructor(
    private apiService: APIService,
    private groupsService: GroupService,
    private inverterService: InverterService
  ) {}

  selectedGroup = computed(() => {
    const selectedGroup = this.groupsService.selectedGroups()[0]?.id;
    return this.groupsService.groupsQuery.data()?.find(e => e.id === Number(selectedGroup));
  });

  getOptimalKwh = computed(() => {
    const panelAmount = this.groupsService
      .selectedGroups()
      .reduce((sum, group) => sum + (group.solarPanelAmount ?? 0), 0);

    const batteryAmount = this.groupsService
      .selectedGroups()
      .reduce((sum, group) => sum + (group.batteries?.length ?? 0), 0);

    if (!panelAmount) {
      return 0;
    }

    const currentPotential = this.getPotential(panelAmount, batteryAmount);
    const baseKwh = this.calculatePotentialYearlyKwh(this.monthlyData.data()?.monthly ?? []);

    return baseKwh / currentPotential;
  });

  monthlyData = injectQuery(() => ({
    queryKey: ['monthlyData', this.selectedGroup()?.inverter?.deviceId],
    enabled: () => !!this.selectedGroup()?.inverter?.deviceId && this.groupsService.potentialFeatureEnabled(),
    queryFn: async () => {
      const data = await this.inverterService.getMonthlyData(this.selectedGroup()!.inverter!.deviceId!, '2024');

      if (!data) {
        return {
          summary: 0,
          monthly: [],
        };
      }

      return {
        summary: Number(data.summary),
        monthly: data.data,
      };
    },
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  }));

  inverterAggregatedQry = injectQuery(() => ({
    queryKey: ['inverterData', this.selectedGroup()?.inverter?.deviceId],
    enabled: () => !!this.selectedGroup()?.inverter?.deviceId,
    queryFn: async () => {
      const { data, error } = await this.apiService.get('/v1/inverter/{deviceId}/aggregated', {
        params: {
          path: {
            deviceId: this.selectedGroup()!.inverter!.deviceId!,
          },
        },
      });

      if (error) {
        throw error;
      }

      return data;
    },
  }));

  calculatePotentialYearlyKwh(currentMonths: number[]) {
    if (currentMonths.length === 0) {
      return 0;
    }

    const baseArray = [39, 36, 73, 93, 87, 102, 103, 100, 67, 40, 23, 15];

    const monthsWithData = currentMonths.filter(month => month > 0);

    const firstMonthWithData = currentMonths.findIndex(month => month > 0);
    const nextMonthAfterFirstWithData = currentMonths[firstMonthWithData + 1];

    let basis = nextMonthAfterFirstWithData ?? currentMonths[firstMonthWithData];

    if (monthsWithData.length < 3) {
      // We dont wanna use the started second month
      basis = currentMonths[firstMonthWithData];
    }

    const factor = basis / baseArray[firstMonthWithData];

    const sum = baseArray.reduce((acc, curr) => acc + curr * factor, 0);

    return sum;
  }

  calculateMax(value: number, percentage: number) {
    if (percentage <= 0) {
      return 0;
    }

    return value / percentage;
  }

  getPotential(panels: number, batteries: number): number {
    const potentialTable: Record<number, Record<number, number>> = {
      // 1 Panel
      1: { 0: 0.35, 1: 0.43, 2: 0.51, 3: 0.59, 4: 0.67 },
      // 2 Panels
      2: { 0: 0.4, 1: 0.48, 2: 0.56, 3: 0.64, 4: 0.72 },
      // 3 Panels
      3: { 0: 0.52, 1: 0.6, 2: 0.68, 3: 0.76, 4: 0.84 },
      // 4 Panels
      4: { 0: 0.64, 1: 0.72, 2: 0.8, 3: 0.88, 4: 1.0 },
    };

    if (potentialTable[panels] && potentialTable[panels][batteries] !== undefined) {
      return potentialTable[panels][batteries];
    } else {
      return 0;
    }
  }

  calculatePotentialSteps(currentPanelAmount: number, batteryAmount: number) {
    const currentPotential = this.getPotential(currentPanelAmount, batteryAmount);

    const baseKwh = this.calculatePotentialYearlyKwh(this.monthlyData.data()?.monthly ?? []);
    const maxKwh = this.calculateMax(baseKwh, currentPotential);

    const steps = [
      {
        panels: currentPanelAmount,
        potential: currentPotential,
        kwh: baseKwh,
        percentageIncrease: 0,
      },
    ];

    if (currentPanelAmount < 4) {
      const potentialWithOneMore = this.getPotential(currentPanelAmount + 1, batteryAmount);
      const kwhWithOneMore = maxKwh * potentialWithOneMore;
      steps.push({
        panels: currentPanelAmount + 1,
        potential: potentialWithOneMore,
        kwh: kwhWithOneMore,
        percentageIncrease: ((kwhWithOneMore - baseKwh) / baseKwh) * 100,
      });
    }

    if (currentPanelAmount < 3) {
      const potentialWithTwoMore = this.getPotential(currentPanelAmount + 2, batteryAmount);
      const kwhWithTwoMore = maxKwh * potentialWithTwoMore;
      steps.push({
        panels: currentPanelAmount + 2,
        potential: potentialWithTwoMore,
        kwh: kwhWithTwoMore,
        percentageIncrease: ((kwhWithTwoMore - baseKwh) / baseKwh) * 100,
      });
    }

    return steps;
  }
}
